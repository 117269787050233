import React, { useEffect, useMemo, useState } from 'react';
import BlueArrow from '@styles/icons/blue-bottom-arrow.webp';
import LeftArrow from '@styles/icons/left-arrow-in-modal.svg';
import * as Styled from './new-quote-modal.styled';
import { IPost, PostType } from '@/common/types/post.type';
import { useAllQuotePostsByPostId, useCreateRepost, useGetQuotePostsByPostId, usePost } from '@/common/hooks';
import { PostComponent } from '@/common/components/post';
import { NewMusingInputsComponent } from '@/common/components/new-musing-modal/new-mussing-inputs.component';
import { INewPostReply } from '@/common/components/new-musing-modal/new-musing-modal.types';
import { PostRowComponent } from '../post-row';
import { Loader } from '@/common/components/loader/loader.styled';
import { wait } from '@/common/utils/wait';
import CloseIcon from '@/styles/icons/close-modal-icon.webp';

interface IProps {
  open: boolean;
  postId: string;
  userProfileId: string;
  openModal: (newPostId: string) => void;
  closeModal: () => void;
  quotePostHistory: string[];
  setQuotePostHistory: React.Dispatch<React.SetStateAction<string[]>>;
}

export const NewQuotePostModal = ({
  open,
  postId,
  userProfileId,
  openModal,
  closeModal,
  quotePostHistory,
  setQuotePostHistory
}: IProps) => {
  const [postIdInModal, setPostIdInModal] = useState(postId);
  const [postInRow, setPostInRow] = useState<IPost | undefined>(undefined);

  const [isScrollDisabled, setIsScrollDisabled] = useState(false);

  const { mutateAsync: fetchPost, isLoading } = usePost();

  const { mutateAsync: createQuotePost, isSuccess, isLoading: isCreateLoading, reset } = useCreateRepost(userProfileId);

  const { allPosts: posts } = useAllQuotePostsByPostId(postIdInModal);
  const { fetchNextPageList, hasNextPageList, isListLoading, count, refetchList } = useGetQuotePostsByPostId(postIdInModal);

  const updatePost = async () => {
    if (!postIdInModal) return;
    const data = await fetchPost({ id: postIdInModal });
    if (data) {
      await setPostInRow(data);
    }
  };

  useEffect(() => {
    if (postId && postIdInModal !== postId) {
      setPostIdInModal(postId);
    }
  }, [postId]);

  useEffect(() => {
    updatePost();
    refetchList();
  }, []);

  useEffect(() => {
    updatePost();
  }, [postIdInModal]);

  const closeHandler = () => {
    closeModal();
    setQuotePostHistory([]);
  };

  const createClick = (text: string, image: ArrayBuffer | string | null, replies: INewPostReply[], isPrivate: boolean) => {
    if (text.length || image) {
      createQuotePost({ text, image, replies, postId: postIdInModal, isRepost: false, isPrivate });
    }
  };

  const onClickByNextButton = () => {
    fetchNextPageList();
  };

  const openInsideQuotePost = async (newPostId: string, oldPostId: string) => {
    if (!openModal) return;
    await setQuotePostHistory((prev) => [...prev, oldPostId]);
    await closeModal();
    await setPostIdInModal(newPostId);
    await wait(300);
    await openModal(newPostId);
  };

  const openPreviousQuotePost = async () => {
    if (!openModal) return;
    const filterWithoutLast = quotePostHistory.filter((item) => item !== quotePostHistory[quotePostHistory.length - 1]);
    await setQuotePostHistory(filterWithoutLast ?? []);
    await closeModal();
    await setPostIdInModal(quotePostHistory[quotePostHistory.length - 1]);
    await wait(300);
    await openModal(quotePostHistory[quotePostHistory.length - 1]);
  };
  const openPrevious = useMemo(() => !!quotePostHistory.length && openPreviousQuotePost, [quotePostHistory?.length]);
  return (
    <Styled.Wrapper
      isScrollDisabled={isScrollDisabled}
      open={open}
      closeHandler={closeHandler}
      hideChildBackground
      hideChildeShadow
      childTopPostion={15}
      isCreateModal
    >
      <Styled.NewQuotePostWrapper>
        {!!quotePostHistory.length && (
          <Styled.PreviousButton onClick={openPrevious || undefined}>
            <Styled.PrevieusButtonImage src={LeftArrow} alt="Left Arrow" />
            <span>back</span>
          </Styled.PreviousButton>
        )}
        {isLoading && !postInRow && <Loader width="50" height="50" color="black" />}
        {postInRow && (
          <>
            <Styled.NewQuotePostHeader>
              <Styled.ModalTitle>Quote this post</Styled.ModalTitle>
              {!quotePostHistory.length && <Styled.CloseImage onClick={closeHandler} src={CloseIcon} alt="close-icon" />}
            </Styled.NewQuotePostHeader>

            <PostComponent
              userProfileId={userProfileId}
              postStatus={{ isInOwnTread: true, isQuote: true }}
              post={postInRow}
              postType={PostType.Quote}
            />

            <Styled.PostComponentWrapper>
              <NewMusingInputsComponent
              buttonsTopMargin={30}
                closeHandler={closeHandler}
                createClick={createClick}
                reset={reset}
                isLoading={isCreateLoading}
                isSuccess={isSuccess}
                open={open}
                setIsScrollDisabled={setIsScrollDisabled}
                isInNewQuote
              />
            </Styled.PostComponentWrapper>
          </>
        )}
      </Styled.NewQuotePostWrapper>
      {count !== 0 && (
        <div style={{ marginLeft: '20px', height: '2px' }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="562" height="2" viewBox="0 0 562 2" fill="none">
            <path d="M1 1L561 1" stroke="#EAEAEA" strokeLinecap="square" />
          </svg>
        </div>
      )}
      {count !== 0 && (
        <Styled.QuotePostsWrapper>
          {isListLoading ? (
            <Loader width="50" height="50" color="black" />
          ) : (
            <>
              <Styled.ModalTitle isOtherQuotePostsTitle isEmpty={!count}>
                {!count ? 'No quote-posts yet' : count === 1 ? `${count} quote-post` : `${count} quote-posts`}
              </Styled.ModalTitle>
              {posts?.map((post, index) => (
                <PostRowComponent
                  post={post}
                  closeModal={closeHandler}
                  quotePostHandler={(newPostId) => openInsideQuotePost(newPostId, postIdInModal)}
                  userProfileId={userProfileId}
                  isLast={index === (count ? count - 1 : 0)}
                  isInQuoteModal
                  isInModal
                />
              ))}
              {hasNextPageList && (
                <Styled.ViewMoreButton onClick={onClickByNextButton}>
                  <Styled.BlueArrowDown src={BlueArrow} alt="Blue Arrow" />
                  <span>view more quote-posts</span>
                </Styled.ViewMoreButton>
              )}
            </>
          )}
        </Styled.QuotePostsWrapper>
      )}
    </Styled.Wrapper>
  );
};
